* {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.pageTitle {
  color: #434A54;
}

::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #AAB2BD;
  border-radius: 10px;
}

.App {
  background-color:#edf0f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}

.grid-container {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 12vh 78vh 10vh;
  grid-template-rows: 12vh 78vh 10vh;
  grid-template-areas: "profile" "menu" "logout";
  height: 100vh;
}

.profile { 
  -ms-grid-row: 1; 
  grid-row: 1;
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: profile; 
  -ms-grid-column-align: center; 
      justify-self: center;
  -ms-grid-row-align: center;
      align-self: center;
}

.logout { 
  -ms-grid-row: 3; 
  grid-row: 3; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: logout; 
  -ms-grid-row-align: end stretch; 
  align-self: end stretch;
}

.menu {
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 1;
  grid-column: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 14vh 14vh 14vh 14vh 14vh;
  grid-template-rows: 14vh 14vh 14vh 14vh 14vh;
      grid-template-areas: "." "." "." "." ".";
  grid-area: menu;
  -ms-grid-row-align: start;
      align-self: start;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  grid-auto-flow: row;
}

@media all and (-ms-high-contrast:none) {
  .grid-container {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 20% 70% 10%;
  }

  .menu {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 20% 20% 20% 20% 200%;
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .perfil {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-flex-item-align: center;
    justify-self: center;
    align-self: center;
  }

  .logout {
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 1;
  }
}
.form-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "form-header" "form-conteudo" "form-botao";
  height: 79vh;
  width: 30vw;
}

.form-grid-container .form-header { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: form-header; 
  -ms-grid-row-align: center; 
      align-self: center;
}

.form-grid-container .form-conteudo { 
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  display: -ms-inline-grid; 
  display: inline-grid;
  grid-area: form-conteudo; 
  align-self: flex-start;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 3fr 1fr 3fr;
  grid-template-rows: 3fr 1fr 3fr;
      grid-template-areas: "form-user" "." "form-pass";
  -webkit-box-align: space-between;
      -ms-flex-align: space-between;
          align-items: space-between;
  width: 30vw;
}

.form-grid-container .form-botao { 
  -ms-grid-row: 3; 
  grid-row: 3; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: form-botao; 
  -ms-grid-column-align: center; 
      justify-self: center;
  -ms-grid-row-align: center;
      align-self: center;
}

.form-grid-container .form-conteudo .form-user { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: form-user; 
  -ms-grid-row-align: center; 
      align-self: center;
}

.form-grid-container .form-conteudo .form-pass { 
  -ms-grid-row: 3; 
  grid-row: 3; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: form-pass; 
  -ms-grid-row-align: center; 
      align-self: center;
}

.tabela th{
  text-align: left !important;
}

.MTableToolbar-root-263{
  padding-right: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.MuiTableCell-alignRight{
  text-align: left !important;
}

.cabecalho-grid-container {
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10fr 3fr 1fr;
  grid-template-columns: 1fr 20fr 3fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "cabecalho-menu cabecalho-titulo cabecalho-btn-exportar cabecalho-info";
  width: 100%;
}

.cabecalho-menu { 
  -ms-grid-row: 1; 
  justify-self: start;
  -ms-grid-column-align: start; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: cabecalho-menu; 
}

.cabecalho-btn-exportar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.cabecalho-titulo { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: cabecalho-titulo; 
}

.cabecalho-info { 
  -ms-grid-row: 1;
  grid-row: 1;  
  -ms-grid-column: 3; 
  justify-self: end;
  -ms-grid-column-align: end; 
  grid-column: 3; 
  grid-area: cabecalho-info; 
}

.graficos-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graficos-flex-container-item {
  flex: 1;
  margin: 0px 0px 10px 0px;
}

.graficos-flex-container-space-item {
  width: 100%;
}

.home-content {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex-direction: column;
}

.home-content-group {
  flex: 1;
  display: flex;
  justify-content: center;
}

.home-content-card {
  flex: 1;
  min-width: 275px;
  flex-basis: 30vw;
  margin-bottom: 10px;
  margin-right: 10px;
}

.home-content-card:last-child {
  margin-right: 0px;
}

@media (max-width: 700px) {
  .cabecalho-btn-exportar {
    display: none;
  }
}

@media print {
  .home-content {
    display: table;
    width: 95%;
    border-spacing: 5px;
  }

  .home-content-group {
    display: table-row;
    page-break-inside: avoid;
  }
  
  .home-content-card {
    display: table-cell;
    width: 30vw;
  }

  .graficos-flex-container {
    display: table;
    width: 100%;
    border-spacing: 0px 20px;
    border-collapse: separate;
  }
  
  .graficos-flex-container-item {
    display: table-row;
    page-break-inside: avoid;
  }

  .graficos-flex-container-space-item {
    height: 70px;
  }
}

.highcharts-button-symbol {
  cursor: pointer;
}

.MuiTableCell-sizeSmall:last-child {
  padding-right: 0 !important;
}

.lt-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 1fr;
  grid-template-columns: 3fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". lt-imagem";
  align-items: flex-start;
}

.lt-imagem { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 3; 
  grid-column: 3; 
  grid-area: lt-imagem; 
  justify-self: flex-end;
}

.filtro-header-grid-container {
  max-height: 180px;
  grid-gap: 0px 24px;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "filtro-header-filtro filtro-header-fechar" "filtro-header-inicio filtro-header-fim" "filtro-header-linha filtro-header-laminador";
}

.filtro-header-grid-container-corrente {
  max-height: 180px;
  grid-gap: 0px 24px;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "filtro-header-filtro filtro-header-fechar" "filtro-header-linha-corrente filtro-header-laminador-corrente";
}

.filtro-header-filtro { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-header-filtro; 
}

.filtro-header-fechar { 
  justify-self: end;
  -ms-grid-column-align: end;
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-header-fechar; 
}

.filtro-header-inicio { 
  margin-bottom: 24px;
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-header-inicio; 
}

.filtro-header-fim { 
  margin-bottom: 24px;
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-header-fim; 
}

.filtro-header-linha { 
  margin-bottom: 24px;
  -ms-grid-row: 3; 
  grid-row: 3; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-header-linha; 
}

.filtro-header-laminador { 
  margin-bottom: 24px;
  grid-row: 3; 
  -ms-grid-row: 3; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-header-laminador; 
}

.filtro-header-linha-corrente { 
  margin-bottom: 24px;
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-header-linha-corrente; 
}

.filtro-header-laminador-corrente { 
  margin-bottom: 24px;
  grid-row: 2; 
  -ms-grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-header-laminador-corrente; 
}

.filtro-tipo-grid-container {
  margin-top: 12px;
  grid-gap: 0px 24px;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "filtro-tipo-label filtro-tipo-item";
  max-height: 90px;
}

.filtro-tipo-label { 
  max-height: 90px;
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-tipo-label; 
}

.filtro-tipo-item {
  max-height: 90px; 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-tipo-item; 
}

.filtro-meio-grid-container {
  margin-top: 24px;
  grid-gap: 0px 24px;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "filtro-meio-fornecedor" "filtro-meio-material";
}

.filtro-meio-fornecedor {
  margin-bottom: 24px; 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-meio-fornecedor; 
}

.filtro-meio-material {
  margin-bottom: 24px;
  grid-row: 2; 
  -ms-grid-row: 2; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: filtro-meio-material; 
}

.filtro-botoes-grid-container {
  grid-gap: 0px 24px;
  max-height: 72px;
  display: grid;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: ". ." "filtro-botoes-limpar filtro-botoes-filtrar";
  position: absolute;
  bottom: 0px;
  width: 95%;
  padding: 16px;
}

@media (max-height: 600px) {
  .filtro-botoes-grid-container {
    position: relative;
  }
}

.filtro-botoes-limpar { 
  grid-row: 1; 
  -ms-grid-row: 1; 
  grid-column: 1; 
  -ms-grid-column: 1; 
  grid-area: filtro-botoes-limpar; 
  -ms-grid-row-align: end stretch; 
  align-self: end stretch;
}

.filtro-botoes-filtrar { 
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: filtro-botoes-filtrar; 
  -ms-grid-row-align: end stretch; 
  align-self: end stretch;
}

.footer {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
}

.footer .item {
  flex: 1 1 50%;
}

.footer .item:nth-child(3){ 
  margin-left: 24px;
}

body {
  div.MuiDrawer-root.MuiDrawer-modal {
    div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
      padding: 16px;
      form {
        div {
          div.filtro-tipo-custo-grid-container {
            div.filtro-tipo-custo-fornecedorBKP > div {
              width: 100%;
            }
            div.filtro-tipo-custo-materialBKP > div{
              width: 100%;
              max-width: 238px;
            }
            div.filtro-tipo-custo-fornecedorWR > div {
              width: 100%;
            }
            div.filtro-tipo-custo-materialWR > div{
              width: 100%;
              max-width: 238px;
            }
          } 
          div.filtro-tipo-grid-container {
            div.filtro-tipo-label > label {
              margin-bottom: 0px !important;
              padding-top: 11px;
              padding-bottom: 11px;
            }
            div:nth-child(2) > fieldset {
              margin-top: 0px;
            }
          }
          div.filtro-meio-grid-container {
            div.filtro-meio-material > div {
              width: 100%;
            }
            div.filtro-meio-fornecedor > div {
              width: 100%;
            }  
          }

          div.filtro-header-grid-container {
            div:nth-child(2) {
              justify-self: end;
              -ms-grid-column-align: end;
            }
            div.filtro-header-linha > div {
              width: 100%;
            }
            div.filtro-header-laminador > div {
              width: 100%;
            }
            div:nth-child(3) > div {
              width: 100%;
            }
            div:nth-child(5) > div {
              width: 100%;
            }
          } 

          div.filtro-header-grid-container-corrente {
            div:nth-child(2) {
              justify-self: end;
              -ms-grid-column-align: end;
            }
            div.filtro-header-linha-corrente > div {
              width: 100%;
            }
            div.filtro-header-laminador-corrente > div {
              width: 100%;
            }
          } 
          
          div.filtro-botoes-grid-container {
            div.filtro-botoes-limpar {
              justify-self: center;
            }
          }
          
          div.footer {
            div:nth-child(3) > div {
              width: 100%;
            }
            div:nth-child(2) > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#aplicar{
  width: 96%;
}

#filtroOculto {
  visibility: hidden;
  height: 0px;
}

@media print {
  #filtroOculto {
    visibility: visible;
    height: unset,
  }
}

#marcarTodosCilindros:focus{
  outline:none;
}

.tipo-custo-grid-container {
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "tipo-custo-bur tipo-custo-wr";
}

.tipo-custo-bur { 
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: tipo-custo-bur; 
}

.tipo-custo-wr { 
  margin-left: 11px;
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: tipo-custo-wr; }

  #previsaoProducaoEdit{
    max-height: 60px;
  }

  #previsaoProducaoEditBody{
    padding: 0px 24px !important;
  }

  #previsaoProducaoEditActions{
    max-height: 55px;
    padding-right: 30px;
  }

  #previsaoConsumoEdit{
    max-height: 60px;
  }

  #previsaoConsumoEditBody{
    padding: 0px 24px !important;
  }

  #previsaoConsumoEditActions{
    max-height: 55px;
    padding-right: 30px;
  }

  #previsaoRecebimentoEdit{
    max-height: 60px;
  }

  #previsaoRecebimentoEditBody{
    padding: 0px 24px !important;
  }

  #previsaoRecebimentoEditActions{
    max-height: 55px;
    padding-right: 30px;
  }
  