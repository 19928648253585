.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: #edf0f4;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 2px 5px black;
}

.grid-container-loading {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas: ". . ." ". spinner ." ". label ." ". . .";
  height: 100vh;
}

.grid-container-loading .spinner { 
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: spinner; 
  -ms-grid-row-align: center; 
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.grid-container-loading .label { 
  -ms-grid-row: 3; 
  grid-row: 3;
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: label; 
  color: #004070;
  font-weight: bold;
  font-size: 18px;
  -ms-grid-column-align: center;
      justify-self: center;
  align-self: flex-start;
}

.grid-container-table {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas: ". . ." ". spinner ." ". label ." ". . .";
  height: 480px;
}

.grid-container-table .spinner { 
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: spinner; 
  -ms-grid-row-align: center; 
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.grid-container-table .label { 
  -ms-grid-row: 3; 
  grid-row: 3;
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: label; 
  color: #004070;
  font-weight: bold;
  font-size: 18px;
  -ms-grid-column-align: start;
      justify-self: center;
  align-self: flex-start;
}


.grid-container-modal {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas: ". . ." ". spinner ." ". label ." ". . .";
  height: 100px;
}

.spinner-modal .sk-spinner {
  transform: scale(0.7);
}

.grid-container-modal .spinner-modal { 
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: spinner; 
  -ms-grid-row-align: center; 
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.grid-container-modal .label { 
  -ms-grid-row: 3; 
  grid-row: 3;
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: label; 
  color: #004070;
  font-weight: bold;
  font-size: 18px;
  -ms-grid-column-align: start;
      justify-self: center;
  align-self: flex-start;
}